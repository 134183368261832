<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF jE mb-4">
			<div class="dF">
				<div>
					<a-input ref="searchInput" v-model="searchQuery" placeholder="Search sms..." style="width: 300px"
						size="large">
						<a-icon slot="prefix" type="search" />
					</a-input>
				</div>
			</div>
		</div>
		<a-card style="padding: 0" class="table-card">
			<a-table class="tableStyle" :columns="columns" :data-source="filtered" :row-key="(record) => record.id"
				:loading="loading">
				<div slot="name" slot-scope="record" class="dF fC">
					{{ record.name }}
					<span v-if="record.initialBroadcast && getBroadcastName(record.initialBroadcast)"
						style="color: var(--med-gray)">
						Previous Broadcast: {{ getBroadcastName(record.initialBroadcast) }}
					</span>
				</div>
				<div slot="instanceName" slot-scope="record">
					{{ getInstanceName(record.instance) }}
				</div>
				<div slot="dateSent" slot-scope="record" class="dF">
					<span>{{ formatDate(record.sentAt) }}</span>
				</div>
				<div slot="total" slot-scope="record" class="dF">
					<span>{{ record.counts && record.counts.total || 0 }}</span>
				</div>
				<div slot="accepted" slot-scope="record" class="dF">
					<span v-if="!record.counts">N/A</span>
					<span v-else-if="isNaN(record.counts.accepted)">N/A</span>
					<span v-else-if="!record.counts.accepted">0% <small>(0)</small></span>
					<span v-else>
						<span style="display: block">
							{{
								parseInt(
									(record.counts.accepted /
										record.counts.total) *
									100
								)
							}}%
						</span>
						<small> ({{ record.counts.accepted }}) </small>
					</span>
				</div>
				<div slot="sent" slot-scope="record" class="dF">
					<span v-if="!record.counts">N/A</span>
					<span v-else-if="isNaN(record.counts.sent)">N/A</span>
					<span v-else-if="!record.counts.sent">0% <small>(0)</small></span>
					<span v-else>
						<span style="display: block">
							{{
								parseInt(
									(record.counts.sent /
										record.counts.accepted) *
									100
								)
							}}%
						</span>
						<small> ({{ record.counts.sent }}) </small>
					</span>
				</div>
				<div slot="delivered" slot-scope="record" class="dF">
					<span v-if="!record.counts">N/A</span>
					<span v-else-if="isNaN(record.counts.delivered)">N/A</span>
					<span v-else-if="!record.counts.delivered">0% <small>(0)</small></span>
					<span v-else>
						<span style="display: block">
							{{
								parseInt(
									(record.counts.delivered /
										record.counts.sent) *
									100
								)
							}}%
						</span>
						<small> ({{ record.counts.delivered }}) </small>
					</span>
				</div>
				<div slot="actions" slot-scope="record" class="dF aC" style="gap: 10px;">
					<a-button @click="previewEmail(record)">Preview SMS</a-button>
					<a-button @click="$router.push(`/sms/${record.id}`)">View Report</a-button>
				</div>
			</a-table>
		</a-card>
		<a-modal v-model="previewModal" :title="broadcast.name" :footer="null">
			<div>
				{{ broadcast.messagebody }}
			</div>
		</a-modal>
	</div>
</template>

<script>
import { formatDate } from "bh-mod";

export default {
	components: {},
	watch: {},
	data() {
		return {
			loading: false,
			previewModal: false,
			broadcast: {},
			searchQuery: "",
			broadcasts: [],
			completedColumns: [
				{
					title: "Name",
					scopedSlots: { customRender: "name" },
					key: "name",
					sorter: (a, b) =>
						a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
					width: "25%",
				},
				{
					title: "Date Sent",
					scopedSlots: { customRender: "dateSent" },
					key: "dateSent",
					sorter: (a, b) => a.sentAt - b.sentAt,
				},
				{
					title: "Total",
					scopedSlots: { customRender: "total" },
					key: "total",
					sorter: (a, b) =>
						((a.counts && a.counts.total) || 0) -
						((b.counts && b.counts.total) || 0),
				},
				{
					title: "Accepted",
					scopedSlots: { customRender: "accepted" },
					key: "accepted",
					sorter: (a, b) =>
						((a.counts && a.counts.accepted) || 0) -
						((b.counts && b.counts.accepted) || 0),
				},
				{
					title: "Sent",
					scopedSlots: { customRender: "sent" },
					key: "sent",
					sorter: (a, b) =>
						((a.counts && a.counts.sent) || 0) -
						((b.counts && b.counts.sent) || 0),
				},
				{
					title: "Delivered",
					scopedSlots: { customRender: "delivered" },
					key: "delivered",
					sorter: (a, b) =>
						((a.counts && a.counts.delivered) || 0) -
						((b.counts && b.counts.delivered) || 0),
				},
				{
					title: "",
					scopedSlots: { customRender: "actions" },
					key: "actions",
					width: "5%",
				},
			],
		};
	},
	computed: {
		filtered() {
			let result = this.broadcasts;
			if (this.searchQuery) {
				let query = this.searchQuery.toLowerCase();
				result = result.filter((item) => item.name.toLowerCase().includes(query));
			}
			return result.sort((a, b) => b.sentAt - a.sentAt);
		},

		instances() {
			let result = [{
				id: this.$store.state.instance.id,
				name: this.$store.state.instance.name
			}]
			const children = this.$store.state.instance.children
			result.push(...children)

			return result;
		},

		columns() {
			let result = this.completedColumns
			if (this.instances && this.instances.length > 1) {
				result.splice(1, 0, {
					title: "Project",
					scopedSlots: { customRender: "instanceName" },
					key: "instanceName",
					sorter: (a, b) => {
						const aa = this.getInstanceName(a.instance);
						const bb = this.getInstanceName(b.instance);

						return aa < bb ? -1 : aa > bb ? 1 : 0;
					}
				})
			}

			return result;
		}
	},
	methods: {
		formatDate,
		async getBroadcasts() {
			this.loading = true;
			try {
				const { data } = await this.$api.get(`sms-broadcasts/:instance`);
				this.broadcasts = data.filter(d => d.sentAt);
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while getting broadcast list. Please try again.`))
				}
			}
			this.loading = false;
		},

		getBroadcastName(id) {
			const broadcast = this.broadcasts.find(b => b.id === id)
			return broadcast ? broadcast.name : null;
		},

		previewEmail(broadcast) {
			this.broadcast = broadcast;
			this.previewModal = true;
		},

		getInstanceName(id) {
			if (this.instances) {
				const find = this.instances.find(i => i.id === id);
				if (find) {
					return find.name;
				}
				return "";
			}
			return "";
		}
	},
	created() {
		this.getBroadcasts();
	},
};
</script>

<style scoped>

</style>
